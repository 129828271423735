module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":512,"linkImagesToOriginal":false,"quality":100},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mallik: Bangalore based Professional Photographer","short_name":"mallik.in","start_url":"/","background_color":"#f0f0f0","theme_color":"#20302a","display":"standalone","icon":"static/images/icon.png"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-ESD1NLN1ZM"]},
    }]
